/**
 * Font Awesome Pro 6 icons
 * https://fontawesome.com/search?s=brands%2Clight
 */
export {
	faAddressCard,
	faAngleRight,
	faArchway,
	faArrowLeft,
	faArrowRight,
	faArrowRotateRight,
	faArrowsRepeat,
	faBadgeCheck,
	faBars,
	faBoxesStacked,
	faBringForward,
	faBuildingColumns,
	faBullhorn,
	faBusinessTime,
	faCalculator,
	faCalendar,
	faCalendarAlt,
	faCalendarHeart,
	faCashRegister,
	faChalkboardUser,
	faChartColumn,
	faChartLineUp,
	faChartMixed,
	faChartNetwork,
	faChartTreeMap,
	faCheck,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircleCheck,
	faCircleNotch,
	faCircleQuestion,
	faCircleXmark,
	faClock,
	faClose,
	faCoins,
	faCopy,
	faCubes,
	faDesktop,
	faDisplay,
	faDownload,
	faEnvelope,
	faExclamationTriangle,
	faExternalLink,
	faEye,
	faEyeSlash,
	faFile,
	faFileChartColumn,
	faFileChartPie,
	faFilePdf,
	faFileSignature,
	faFileVideo,
	faFilter,
	faGlobe,
	faHandHoldingDollar,
	faHandshake,
	faHeadset,
	faHome,
	faHouseBuilding,
	faIndustryWindows,
	faInfoCircle,
	faLayerPlus,
	faLightbulb,
	faLink,
	faLocationDot,
	faLocationDotSlash,
	faMagnifyingGlass,
	faMailBulk,
	faMessageDots,
	faMinus,
	faMoneyBillTransfer,
	faNotebook,
	faPaperPlane,
	faPeopleLine,
	faPhone,
	faPlay,
	faPlug,
	faPlus,
	faReceipt,
	faRocket,
	faScannerTouchscreen,
	faSearch,
	faSeedling,
	faShareNodes,
	faSignalBars,
	faStar as lightStar,
	faStore,
	faTag,
	faTags,
	faThumbsUp,
	faTrashCan,
	faTreeDeciduous,
	faUser,
	faVideo,
	faXmark,
	faMobile,
	faLaptop,
	faUsers,
	faUserTag,
	faXmarkLarge,
	faCircleEllipsis,
} from '@fortawesome/pro-light-svg-icons';

export { faCloudArrowUp } from '@fortawesome/pro-light-svg-icons/faCloudArrowUp';

export {
	faEnvelope as faEnvelopeSolid,
	faLocationDot as faLocationDotSolid,
	faSpinner,
	faStar,
	faStarHalf,
	faCircleCheck as faCircleCheckSolid,
	faXmark as faXmarkSolid,
} from '@fortawesome/pro-solid-svg-icons';

export { faFacebookF, faLinkedinIn, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
